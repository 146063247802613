import 'stylesheets/application.scss';
import 'tippy.js/dist/tippy.css';
// import 'tippy.js/themes/light.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'initializers/font_awesome';
import 'whatwg-fetch';
import 'controllers';
import 'channels';

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import _find from 'lodash/find';
import _isFinite from 'lodash/isFinite';

// import { start as activeStorageStart } from '@rails/activestorage';

Turbolinks.start();
// activeStorageStart();
Rails.start();

let scrollPosition = null;
let focusElement = null;
let focusPosition = [null, null];

document.addEventListener(
  'turbolinks:load',
  () => {
    if (scrollPosition) {
      window.scrollTo.apply(window, scrollPosition);
      scrollPosition = null;
    }

    if (focusElement) {
      focusElement.focus();

      if (_isFinite(focusPosition[0]) && focusPosition[0] > 0) {
        focusElement.setSelectionRange(focusPosition[0], focusPosition[1]);
      }
    }
  },
  false
);

Turbolinks.reload = () => {
  focusElement = document.activeElement;
  focusPosition = [focusElement?.selectionStart, focusElement?.selectionEnd];
  scrollPosition = [window.scrollX, window.scrollY];
  Turbolinks.visit(window.location, { action: 'replace' });
};

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

if (navigator.serviceWorker) {
  navigator.serviceWorker
    .register('/serviceworker.js', { scope: './' })
    .then(function (reg) {
      console.log('[Companion]', 'Service worker registered!');
      console.log(reg);
    });
}
