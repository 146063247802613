import AutocompleteController from './autocomplete_controller';
import _get from 'lodash/get';
import { fetchParams } from 'globals';

export default class extends AutocompleteController {
  fetch(search) {
    fetch(`/projects.js?projects_index_form[search]=${search}`, fetchParams())
      .then((response) => response.text())
      .then((body) => {
        this.listTarget.innerHTML = body;
      });
  }

  applyUpdate(value) {
    if (!value) {
      this.buttonTarget.innerHTML = '&nbsp;';
      this.hiddenTarget.value = '';
      return;
    }

    const {
      client: { name: clientName },
      id,
      name,
    } = value;

    let result = name;

    if (this.element.dataset.format === 'client') {
      result += ` (${clientName})`;
    }

    this.buttonTarget.innerHTML = result;
    this.hiddenTarget.value = id;
  }
}
