// import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
// import "@fortawesome/fontawesome-free/scss/regular.scss";
// import "@fortawesome/fontawesome-free/scss/solid.scss";

import { config, dom, library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBan,
  faBars,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckDouble,
  faCircle,
  faCog,
  faCopy,
  faCreditCard,
  faDownload,
  faEllipsisH,
  faEnvelope,
  faEquals,
  faExchangeAlt,
  faExclamationCircle,
  faFileCsv,
  faFilter,
  faHistory,
  faHome,
  faLayerGroup,
  faListAlt,
  faPencilAlt,
  faPlay,
  faPlus,
  faPlusSquare,
  faRedoAlt,
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faStop,
  faStream,
  faTable,
  faTimes,
  faTools,
  faUser,
  faUserPlus,
  faUsers,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';

// import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';

//  Change the config to fix the flicker
config.mutateApproach = 'sync';

library.add(
  faAddressCard,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBan,
  faBars,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
  faCheck,
  faCheckDouble,
  faCircle,
  faCog,
  faCopy,
  faCreditCard,
  faDownload,
  faEllipsisH,
  faEnvelope,
  faEquals,
  faExchangeAlt,
  faExclamationCircle,
  faFileCsv,
  faFilter,
  faHistory,
  faHome,
  faLayerGroup,
  faListAlt,
  faPencilAlt,
  faPlay,
  faPlus,
  faPlusSquare,
  faRedoAlt,
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faStop,
  faStream,
  faTable,
  faTimes,
  faTools,
  faUser,
  faUserPlus,
  faUsers,
  faUsersCog
);

dom.watch();
