import { fetchParams, fromSearch, toSearch } from 'globals';

import RefreshController from './refresh_controller';
import Turbolinks from 'turbolinks';

export default class extends RefreshController {
  static targets = ['select'];

  channelName = 'EntriesChannel';

  dateChange() {
    Turbolinks.visit(`/dashboard?${this._search()}`, {
      action: 'replace',
    });
  }

  _refresh() {
    fetch(`/dashboard.js${window.location.search}`, fetchParams())
      .then((response) => response.text())
      .then((body) => {
        this.element.innerHTML = body;
      });
  }

  _search() {
    return toSearch({
      ...fromSearch(window.location.search),
      date: this.selectTarget.value,
    });
  }
}
