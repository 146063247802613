import _camelCase from 'lodash/camelCase';
import _isArray from 'lodash/isArray';
import _isBoolean from 'lodash/isBoolean';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isObject from 'lodash/isObject';
import _isPlainObject from 'lodash/isPlainObject';
import _isString from 'lodash/isString';
import _merge from 'lodash/merge';
import _reduce from 'lodash/reduce';
import _snakeCase from 'lodash/snakeCase';
import queryString from 'query-string';

const ARRAY_FORMAT = 'bracket';

export const _isBlank = (value) =>
  _isNil(value) ||
  (_isString(value) && value.length === 0) ||
  (_isBoolean(value) && !value) ||
  (_isArray(value) && value.length === 0) ||
  (_isPlainObject(value) && _isEmpty(value));

export const _isPresent = (value) => !_isBlank(value);

export const toSearch = (params) =>
  queryString.stringify(params, {
    arrayFormat: ARRAY_FORMAT,
  });

export const fromSearch = (search) =>
  queryString.parse(search, {
    arrayFormat: ARRAY_FORMAT,
  });

export const fetchParams = (params = {}) =>
  _merge(
    {},
    {
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content'),
      },
    },
    params
  );
