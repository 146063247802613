import SearchFormController from './searchForm_controller';

export default class extends SearchFormController {
  static targets = ['clientField', 'projectField'];

  clientIdChange(event) {
    const {
      detail: { value },
    } = event;

    const clientHidden = this.clientFieldTarget.querySelector(
      'input[type="hidden"]'
    );

    if (value?.id === clientHidden.value) {
      return;
    }

    const projectButton = this.projectFieldTarget.querySelector('button');
    const projectHidden = this.projectFieldTarget.querySelector(
      'input[type="hidden"]'
    );
    const projectSearchIcon = this.projectFieldTarget.querySelector(
      '[data-projectField-target="iconSearch"]'
    );
    const projectTimesIcon = this.projectFieldTarget.querySelector(
      '[data-projectField-target="iconTimes"]'
    );

    projectHidden.value = '';
    projectButton.innerHTML = '&nbsp;';
    projectSearchIcon.classList.remove('hidden');
    projectTimesIcon.classList.add('hidden');
  }

  projectIdChange(event) {
    const {
      detail: { value },
    } = event;

    const projectHidden = this.projectFieldTarget.querySelector(
      'input[type="hidden"]'
    );

    if (value?.id === projectHidden.id) {
      return;
    } else if (value) {
      const clientHidden = this.clientFieldTarget.querySelector(
        'input[type="hidden"]'
      );
      const clientButton = this.clientFieldTarget.querySelector('button');
      const clientSearchIcon = this.clientFieldTarget.querySelector(
        '[data-clientField-target="iconSearch"]'
      );
      const clientTimesIcon = this.clientFieldTarget.querySelector(
        '[data-clientField-target="iconTimes"]'
      );

      clientHidden.value = value.client.id;
      clientButton.innerHTML = value.client.name;
      clientSearchIcon.classList.add('hidden');
      clientTimesIcon.classList.remove('hidden');
    }
  }
}
