import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';

export default class extends Controller {
  static targets = ['form', 'toggle'];

  toggleForm() {
    if (this.formTarget.classList.contains('hidden')) {
      this.formTarget.classList.remove('hidden');
      this.toggleTarget.classList.remove('button--green-500');
      this.toggleTarget.classList.add('button--red-500');

      this.toggleTarget.querySelector('.open').classList.add('hidden');
      this.toggleTarget.querySelector('.close').classList.remove('hidden');
    } else {
      this.formTarget.classList.add('hidden');
      this.toggleTarget.classList.remove('button--red-500');
      this.toggleTarget.classList.add('button--green-500');

      this.toggleTarget.querySelector('.open').classList.remove('hidden');
      this.toggleTarget.querySelector('.close').classList.add('hidden');
    }
  }

  redirect(event) {
    Turbolinks.visit(event.detail[1].url, { action: 'replace' });

    event.preventDefault();
  }
}
