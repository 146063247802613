import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  initialize() {
    this._resize = this._resize.bind(this);
    this._delayedResize = this._delayedResize.bind(this);

    this._resize();
  }

  connect() {
    this.element.addEventListener('change', this._delayedResize, false);
    this.element.addEventListener('cut', this._delayedResize, false);
    this.element.addEventListener('paste', this._delayedResize, false);
    this.element.addEventListener('drop', this._delayedResize, false);
    this.element.addEventListener('keydown', this._delayedResize, false);
  }

  disconnect() {
    this.element.removeEventListener('change', this._delayedResize, false);
    this.element.removeEventListener('cut', this._delayedResize, false);
    this.element.removeEventListener('paste', this._delayedResize, false);
    this.element.removeEventListener('drop', this._delayedResize, false);
    this.element.removeEventListener('keydown', this._delayedResize, false);
  }

  _resize() {
    // this.element.style.height = 'auto';
    // this.element.style.height = '';
    this.element.style.height = `${this.element.scrollHeight + 2}px`;
  }

  _delayedResize() {
    this.element.style.height = '';
    setTimeout(() => this._resize(), 1);
  }
}
