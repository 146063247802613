import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'hourHand',
    'hourHandShadow',
    'minuteHand',
    'minuteHandShadow',
  ];

  connect() {
    if (this.element.dataset.animate !== '1') {
      return;
    }

    const date = new Date();
    const hoursAngle = (360 * date.getHours()) / 12 + date.getMinutes() / 2;
    const minuteAngle = (360 * date.getMinutes()) / 60;

    const shifter = (val) => {
      return [val, 100, 100].join(' ');
    };

    this.minuteHandTarget.setAttribute('from', shifter(minuteAngle));
    this.minuteHandTarget.setAttribute('to', shifter(minuteAngle + 360));

    this.minuteHandShadowTarget.setAttribute('from', shifter(minuteAngle));
    this.minuteHandShadowTarget.setAttribute('to', shifter(minuteAngle + 360));

    this.hourHandTarget.setAttribute('from', shifter(hoursAngle));
    this.hourHandTarget.setAttribute('to', shifter(hoursAngle + 360));

    this.hourHandShadowTarget.setAttribute('from', shifter(hoursAngle));
    this.hourHandShadowTarget.setAttribute('to', shifter(hoursAngle + 360));
  }
}
