import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import { fetchParams } from 'globals';

let clickElement = null;

export default class extends Controller {
  static targets = [];

  yes() {
    let old = Rails.confirm;

    Rails.confirm = () => true;
    Rails.fire(clickElement, 'click');
    Rails.confirm = old;
  }
}

Rails.confirm = (message, element) => {
  fetch(
    '/confirm',
    fetchParams({
      body: JSON.stringify({ confirm: { message } }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
  )
    .then((response) => response.text())
    .then((body) => {
      clickElement = element;
      document.getElementById('portal').innerHTML += body;
    });

  return false;
};
