import { Controller } from 'stimulus';
import _get from 'lodash/get';
import { _isPresent } from 'globals';

export default class AutocompleteController extends Controller {
  static targets = [
    'button',
    'dropdown',
    'hidden',
    'iconSearch',
    'iconTimes',
    'list',
    'text',
  ];

  connect() {
    this.listTarget.innerHTML = '';

    if (_isPresent(this.hiddenTarget.value)) {
      this._updateIcon();
    }
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.debounce) {
      clearTimeout(this.debounce);
      this.debounce = null;
    }
  }

  change(event) {
    this._update(JSON.parse(event.currentTarget.dataset.value));
  }

  close() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.dropdownTarget.classList.add('hidden');
  }

  clear() {
    this._update();
  }

  open() {
    this.dropdownTarget.classList.remove('hidden');
    this.textTarget.focus();
    this.textTarget.select();
  }

  type() {
    const search = encodeURIComponent(this.textTarget.value);

    if (this.debounce) {
      clearTimeout(this.debounce);
      this.debounce = null;
    }

    if (_isPresent(search)) {
      this.debounce = setTimeout(() => {
        this.fetch(search);
      }, 250);
    }
  }

  _updateIcon() {
    if (_isPresent(this.hiddenTarget.value)) {
      this.iconSearchTarget.classList.add('hidden');
      this.iconTimesTarget.classList.remove('hidden');
    } else {
      this.iconSearchTarget.classList.remove('hidden');
      this.iconTimesTarget.classList.add('hidden');
    }
  }

  _update(value = null) {
    this.applyUpdate(value);
    this._updateIcon();

    const newEvent = document.createEvent('CustomEvent');
    newEvent.initCustomEvent('afterChange', true, true, { value });
    this.element.dispatchEvent(newEvent);
  }
}
