import Consumer from 'channels/consumer';
import { Controller } from 'stimulus';
import { _isBlank } from 'globals';

export default class extends Controller {
  static targets = ['overlay', 'sidebar'];

  connect() {
    const html = document.querySelector('html');

    if (this.hasSidebarTarget && this.hasOverlayTarget) {
      // Signed In
      this._handleResize = this._handleResize.bind(this);

      window.addEventListener('resize', this._handleResize);

      this._handleResize();

      html.classList.add('bg-white');
      html.classList.remove('bg-gray-200');
    } else {
      // Signed Out

      html.classList.add('bg-gray-200');
      html.classList.remove('bg-white');

      if (!Consumer.connection.disconnected) {
        Consumer.subscriptions.subscriptions.forEach((subscription) => {
          subscription.unsubscribe();
        });

        Consumer.disconnect();
      }
    }
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    window.removeEventListener('resize', this._handleResize);
  }

  _handleResize() {
    if (document.documentElement.clientWidth >= 768) {
      this.sidebarTarget.classList.add('sidebar-open');
      this.hideOverlay();
    } else {
      this.sidebarTarget.classList.remove('sidebar-open');
    }
  }

  toggleSidebar() {
    if (
      document.documentElement.clientWidth >= 768 ||
      this.sidebarTarget.classList.contains('sidebar-open')
    ) {
      this.hideAll();
    } else {
      this.sidebarTarget.classList.add('sidebar-open');
      this.showOverlay();
    }
  }

  hideAll() {
    this.sidebarTarget.classList.remove('sidebar-open');
    this.hideOverlay();
  }

  showOverlay() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.overlayTarget.classList.remove('hidden');
    document.body.classList.add('overflow-hidden');
    setTimeout(() => this.overlayTarget.classList.add('opacity-100'), 1);
  }

  hideOverlay() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.overlayTarget.classList.remove('opacity-100');
    document.body.classList.remove('overflow-hidden');
    this.timeout = setTimeout(
      () => this.overlayTarget.classList.add('hidden'),
      300
    );
  }
}
