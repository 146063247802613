import Consumer from 'channels/consumer';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  initialize() {
    this._handleVisibilityChange = this._handleVisibilityChange.bind(this);
    this._subscribe();
  }

  connect() {
    document.addEventListener('visibilitychange', this._handleVisibilityChange);
  }

  disconnect() {
    document.removeEventListener(
      'visibilitychange',
      this._handleVisibilityChange
    );
  }

  _subscribe() {
    const element = this.element;

    this.channel = Consumer.subscriptions.create('Projects::RecentsChannel', {
      connected() {
        this.perform('render');
      },
      received({ html }) {
        element.classList.add('divide-y');
        element.classList.remove('h-full');
        element.innerHTML = html;
      },
    });
  }

  _handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this._subscribe();
    } else {
      this.channel.unsubscribe();
    }
  }
}
