import { Controller } from 'stimulus';

export default class extends Controller {
  changeClick(event) {
    const input = event.target.parentNode.parentNode.querySelector(
      '.change-input'
    );

    if (event.target.checked) {
      input.removeAttribute('disabled');
    } else {
      input.setAttribute('disabled', 'disabled');
    }
  }
}
