import AutocompleteController from './autocomplete_controller';
import _get from 'lodash/get';
import { fetchParams } from 'globals';

export default class extends AutocompleteController {
  fetch(search) {
    fetch(`/clients.js?clients_index_form[search]=${search}`, fetchParams())
      .then((response) => response.text())
      .then((body) => {
        this.listTarget.innerHTML = body;
      });
  }

  applyUpdate(value) {
    if (!value) {
      this.buttonTarget.innerHTML = '&nbsp;';
      this.hiddenTarget.value = '';
      return;
    }

    const { id, name } = value;

    this.buttonTarget.innerHTML = name;
    this.hiddenTarget.value = id;
  }
}
