import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content'];

  initialize() {
    this._handleKeyUp = this._handleKeyUp.bind(this);
  }

  connect() {
    document.addEventListener('keyup', this._handleKeyUp);

    setTimeout(() => {
      this.element.classList.add('modal--open');
      this.contentTarget.classList.remove('-translate-y-24');
      document.body.style.overflow = 'hidden';
    }, 1);
  }

  disconnect() {
    this._resetClose();

    document.removeEventListener('keyup', this._handleKeyUp);
  }

  _resetClose() {
    if (this.close) {
      clearTimeout(this.close);
      this.close = null;
    }
  }

  _handleKeyUp(event) {
    if (event.code === 'Escape') {
      this.close();
    }
  }

  close() {
    this._resetClose();
    this.element.classList.remove('modal--open');
    this.contentTarget.classList.add('-translate-y-24');
    document.body.style.overflow = '';

    this.close = setTimeout(() => {
      this.element.parentNode.removeChild(this.element);
    }, 300);
  }
}
