import { Controller } from 'stimulus';
import { createPopper } from '@popperjs/core';

export default class DropdownController extends Controller {
  static targets = ['popper', 'reference'];

  initialize() {
    this.options = {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [
              Number(this.element.dataset.offsetX || 0),
              Number(this.element.dataset.offsetY || 6),
            ],
          },
        },
      ],
      placement: this.element.dataset.placement || 'bottom-end',
    };
  }

  disconnect() {
    if (this.popper) {
      this.hide();
    }
  }

  toggle() {
    if (this.isHidden()) {
      this.show();
    } else {
      this.hide();
    }
  }

  delayHide() {
    if (this.isHidden()) {
      return;
    }
    this._clearTimeout();
    this.timeout = setTimeout(() => this.hide(), 500);
  }

  delayShow() {
    if (this.isHidden()) {
      return;
    }
    this._clearTimeout();
  }

  outsideHide(event) {
    if (this.element.contains(event.target) == false) {
      this.hide();
    }
  }

  hide() {
    if (!this.isHidden()) {
      this.popper.destroy();
      this.popper = null;

      this.popperTarget.classList.add('hidden');

      return true;
    }

    return false;
  }

  show() {
    if (this.isHidden()) {
      this.popperTarget.classList.remove('hidden');

      this.popper = createPopper(
        this.referenceTarget,
        this.popperTarget,
        this.options
      );

      return true;
    }

    return false;
  }

  isHidden() {
    return this.popperTarget.classList.contains('hidden');
  }

  _clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
