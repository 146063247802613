import { Controller } from 'stimulus';
import _padStart from 'lodash/padStart';

export default class extends Controller {
  static targets = ['hours', 'minutes', 'seconds'];

  initialize() {
    this.interval = null;
  }

  increment() {
    let seconds = Number(this.secondsTarget.innerHTML);
    seconds++;

    if (seconds < 60) {
      this.secondsTarget.innerHTML = _padStart(seconds, 2, '0');
    } else {
      this.secondsTarget.innerHTML = '00';

      let minutes = Number(this.minutesTarget.innerHTML);
      minutes++;

      if (minutes < 60) {
        this.minutesTarget.innerHTML = _padStart(minutes, 2, '0');
      } else {
        this.minutesTarget.innerHTML = '00';

        let hours = Number(this.hoursTarget.innerHTML);
        hours++;

        this.hoursTarget.innerHTML = _padStart(hours, 2, '0');
      }
    }

    document.title =
      `${this.hoursTarget.innerHTML}:${this.minutesTarget.innerHTML}:` +
      `${this.secondsTarget.innerHTML} - TimePod`;
  }

  connect() {
    if (this.data.get('disabled') === 'false') {
      this.interval = setInterval(() => this.increment(), 1000);
    } else {
      document.title = 'TimePod';
    }
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
