import Consumer from 'channels/consumer';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select'];

  initialize() {
    this._handleVisibilityChange = this._handleVisibilityChange.bind(this);
    this._refresh = this._refresh.bind(this);
  }

  connect() {
    this._subscribe();

    document.addEventListener('visibilitychange', this._handleVisibilityChange);

    this._startInterval();
  }

  disconnect() {
    document.removeEventListener(
      'visibilitychange',
      this._handleVisibilityChange
    );

    this.channel.unsubscribe();

    this._clearInterval();
  }

  _subscribe() {
    const _responseRefresh = () => this._refresh();

    this.channel = Consumer.subscriptions.create(this.channelName, {
      received(response) {
        if (response.refresh) {
          _responseRefresh();
        }
      },
    });
  }

  _handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this._refresh();
      this._startInterval();

      this._subscribe();
    } else {
      this.channel.unsubscribe();
      this._clearInterval();
    }
  }

  _clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  _startInterval() {
    // every 6 minutes
    this.interval = setInterval(this._refresh, 36000);
  }
}
