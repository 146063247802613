import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['opened', 'closed'];

  toggle(event) {
    event.preventDefault();

    this.openedTargets.forEach((openedTarget) => {
      if (openedTarget.classList.contains('hidden')) {
        openedTarget.classList.remove('hidden');
      } else {
        openedTarget.classList.add('hidden');
      }
    });

    if (this.closedTarget.classList.contains('hidden')) {
      this.closedTarget.classList.remove('hidden');
    } else {
      this.closedTarget.classList.add('hidden');
    }

    return false;
  }
}
