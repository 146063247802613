import { _isPresent, fetchParams } from 'globals';
import {
  addYears,
  endOfDay,
  getMonth,
  getYear,
  isValid,
  parse,
  startOfDay,
} from 'date-fns';

import DropdownController from './dropdown_controller';
import { _isBlank } from '../globals';
import { format } from 'date-fns';
import { toSearch } from 'globals';

export const HOURS_ARRAY = [
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
];

export default class extends DropdownController {
  static targets = [
    'ampm',
    'calendar',
    'helper',
    'hidden',
    'hours',
    'popper',
    'reference',
  ];

  initialize() {
    this.fetching = 0;

    this.options = {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 2],
          },
        },
      ],
      placement: 'bottom-start',
    };
  }

  connect() {
    super.connect();
    this.submitButton = this.element
      .closest('form')
      .querySelector('[type="submit"]');

    // format: 2020-07-29 09:15:00 -0500
    const parsed = parse(
      this.hiddenTarget.value,
      'yyyy-MM-dd HH:mm:ss XX',
      new Date()
    );

    if (isValid(parsed)) {
      this.month = getMonth(parsed) + 1;
      this.year = getYear(parsed);
    } else {
      const now = new Date();
      this.month = now.getMonth() + 1;
      this.year = now.getFullYear();
    }
  }

  disconnect() {
    super.disconnect();

    if (this.debounce) {
      clearTimeout(this.debounce);
    }
  }

  _fetchCalendar(selected, updateReference = true) {
    this.fetching++;
    this.submitButton.disabled = true;
    fetch(
      `/calendar?${toSearch({
        month: this.month,
        selected,
        year: this.year,
      })}`,
      fetchParams({
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      })
    )
      .then((response) => response.text())
      .then((html) => {
        this.calendarTarget.innerHTML = html;
        this.hiddenTarget.value = this.helperTarget.dataset.value;

        if (updateReference || this.isHidden()) {
          this.referenceTarget.value = this.helperTarget.dataset.display;
        }
        this.fetching--;

        if (this.fetching <= 0) {
          this.submitButton.disabled = false;
        }
      });
  }

  focus() {
    this.show();

    this.referenceTarget.select();
  }

  show() {
    if (super.show()) {
      this._fetchCalendar(this.hiddenTarget.value);
    }
  }

  hide() {
    if (super.hide()) {
      if (_isPresent(this.hiddenTarget.value)) {
        if (this.hasHelperTarget) {
          this.referenceTarget.value = this.helperTarget.innerHTML;
        }
      } else {
        this.referenceTarget.value = '';
      }
    }
  }

  keyup() {
    const value = this.referenceTarget.value;

    if (this.debounce) {
      clearTimeout(this.debounce);
      this.debounce = null;
    }

    this.debounce = setTimeout(() => {
      this._fetchCalendar(value, false);
    }, 250);
  }

  keydown(event) {
    if (event.key === 'Escape' || event.key === 'Tab') {
      this.hide();
      return;
    }
  }

  monthChange(event) {
    this.month = event.target.value;

    this._fetchCalendar(this.hiddenTarget.value);
  }

  yearChange(event) {
    this.year = event.target.value;

    this._fetchCalendar(this.hiddenTarget.value);
  }

  previousMonthClick() {
    this.month -= 1;

    if (this.month < 1) {
      this.month = 12;
      this.year -= 1;

      if (this.year < 2000) {
        this.year = 2000;
      }
    }

    this._fetchCalendar(this.hiddenTarget.value);
  }

  nextMonthClick() {
    this.month += 1;

    if (this.month > 12) {
      this.month = 1;
      this.year += 1;

      const maxYear = addYears(new Date(), 10).getFullYear();

      if (this.year > maxYear) {
        this.year = maxYear;
      }
    }

    this._fetchCalendar(this.hiddenTarget.value);
  }

  dayClick(event) {
    if (_isPresent(this.hiddenTarget.value)) {
      const parts = this.hiddenTarget.value.split(' ');

      this._fetchCalendar(`${event.target.dataset.date} ${parts[1]}`);
    } else {
      this._fetchCalendar(event.target.dataset.date);
    }
  }

  hourChange(event) {
    let { value: hour } = event.target;

    if (_isBlank(hour)) {
      this._fetchCalendar('');
      return;
    }

    if (_isPresent(this.hiddenTarget.value)) {
      const ampm = this.ampmTarget.value;

      const dateParts = this.hiddenTarget.value.split(' ');
      const minutes = dateParts[1].split(':')[1];

      this._fetchCalendar(`${dateParts[0]} ${hour}:${minutes} ${ampm}`);
    } else {
      this._fetchCalendar(`${hour}:00 PM`);
    }
  }

  minuteChange(event) {
    const { value: minutes } = event.target;

    if (_isBlank(minutes)) {
      this._fetchCalendar('');
      return;
    }

    if (_isPresent(this.hiddenTarget.value)) {
      const dateParts = this.hiddenTarget.value.split(' ');
      const hourParts = dateParts[1].split(':');

      const hours = HOURS_ARRAY[Number(hourParts[0])].split(' ');
      const seconds = Number(hourParts[2]);

      this._fetchCalendar(
        `${dateParts[0]} ${hours[0]}:${minutes}:${seconds} ${hours[1]}`
      );
    } else {
      this._fetchCalendar(`12:${minutes}:00 PM`);
    }
  }

  ampmChange(event) {
    let { value: ampm } = event.target;

    if (_isBlank(ampm)) {
      this._fetchCalendar('');
      return;
    }

    if (_isPresent(this.hiddenTarget.value)) {
      const hour = this.hoursTarget.value;

      const dateParts = this.hiddenTarget.value.split(' ');
      const hourParts = dateParts[1].split(':');
      const minutes = hourParts[1];
      const seconds = hourParts[2];

      this._fetchCalendar(
        `${dateParts[0]} ${hour}:${minutes}:${seconds} ${ampm}`
      );
    } else {
      this._fetchCalendar(`12:00:00 ${ampm}`);
    }
  }

  clearClick() {
    this._fetchCalendar('');
  }

  todayClick() {
    const now = new Date();
    this.month = now.getMonth() + 1;
    this.year = now.getFullYear();

    this._fetchCalendar(this.hiddenTarget.value);
  }

  nowClick() {
    const now = new Date();
    this.month = now.getMonth() + 1;
    this.year = now.getFullYear();

    this._fetchCalendar(format(new Date(), 'yyyy-MM-dd hh:mm:ss a'));
  }

  beginningOfDayClick() {
    const now = new Date();
    this.month = now.getMonth() + 1;
    this.year = now.getFullYear();

    const date = this.hiddenTarget.value.split(' ')[0];

    this._fetchCalendar(`${date} ${format(startOfDay(now), 'hh:mm:ss a')}`);
  }

  endOfDayClick() {
    const now = new Date();
    this.month = now.getMonth() + 1;
    this.year = now.getFullYear();

    const date = this.hiddenTarget.value.split(' ')[0];

    this._fetchCalendar(`${date} ${format(endOfDay(now), 'hh:mm:ss a')}`);
  }
}
