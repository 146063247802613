import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggle'];

  toggle() {
    if (this.toggleTarget.classList.contains('hidden')) {
      this.toggleTarget.classList.remove('hidden');
    } else {
      this.toggleTarget.classList.add('hidden');
    }
  }
}
