import Consumer from 'channels/consumer';
import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import { _isBlank } from 'globals';

export default class extends Controller {
  static targets = ['description', 'form'];

  initialize() {
    this.ignoreNext = false;
    this._render = this._render.bind(this);
    this._handleVisibilityChange = this._handleVisibilityChange.bind(this);

    this._subscribe();
  }

  connect() {
    document.addEventListener('visibilitychange', this._handleVisibilityChange);
  }

  disconnect() {
    document.removeEventListener(
      'visibilitychange',
      this._handleVisibilityChange
    );
  }

  descriptionChange() {
    if (this.formTarget.dataset.running !== '1') {
      return;
    }

    if (this.debounce) {
      clearInterval(this.debounce);
    }

    this.debounce = setTimeout(() => {
      this._submit();
    }, 1000);
  }

  projectIdChange(_event) {
    if (this.formTarget.dataset.running === '1') {
      this._submit();
    }
  }

  _handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this._subscribe();
    } else {
      this.channel.unsubscribe();
    }
  }

  _submit() {
    this.ignoreNext = true;
    Rails.fire(this.formTarget, 'submit');
  }

  _render({ clock, html }) {
    let position = null;

    if (
      this.hasDescriptionTarget &&
      document.activeElement === this.descriptionTarget
    ) {
      position = this.descriptionTarget.selectionStart;
    }

    this.element.innerHTML = html;

    if (position !== null) {
      this.descriptionTarget.focus();

      if (position > 0) {
        this.descriptionTarget.setSelectionRange(position, position);
      }
    }

    document.getElementById('footer-clock').innerHTML = clock;
  }

  _subscribe() {
    const _render = (response) => this._render(response);
    const ignoreNext = () => this.ignoreNext;
    const stopIgnoreNext = () => (this.ignoreNext = false);

    this.channel = Consumer.subscriptions.create('Entries::StopwatchChannel', {
      connected() {
        this.perform('render');
      },
      received(response) {
        const skip = ignoreNext();
        if (!skip) {
          _render(response);
        }
        stopIgnoreNext();
      },
    });
  }
}
