import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['remove', 'tbody'];

  add() {
    const tbody = this.element.querySelector('tbody');
    const clone = tbody.lastChild.cloneNode(true);

    clone.querySelectorAll('.field_with_errors').forEach((error) => {
      error.classList.remove('field_with_errors');
    });

    tbody.appendChild(clone);

    for (const input of tbody.lastChild.querySelectorAll('input')) {
      if (input.type === 'checkbox' || input.type === 'radio') {
        input.checked = true;
      } else {
        input.value = '';
      }

      input.focus();
    }

    for (const select of tbody.lastChild.querySelectorAll('select')) {
      select.value = select.dataset.default;
    }

    this._refresh();
  }

  remove(event) {
    const rows = this.element.querySelectorAll('.user-row');

    if (rows.length > 1) {
      event.target.closest('tr').remove();
    }

    this._refresh();
  }

  _refresh() {
    const rows = this.element.querySelectorAll('.user-row');

    if (rows.length > 1) {
      for (const row of rows) {
        row.querySelector('button').removeAttribute('disabled');
      }
    } else {
      for (const row of rows) {
        row.querySelector('button').setAttribute('disabled', 'disabled');
      }
    }
  }
}
