import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['flash'];

  initialize() {
    this.dismiss = this.dismiss.bind(this);
  }

  connect() {
    this.timeout = setTimeout(() => {
      this.dismiss();
    }, 5000);
  }

  disconnect() {
    this._clearTimeout();
  }

  dismiss() {
    this.element.classList.add('flashes__flash--removing');

    setTimeout(() => {
      const parent = this.element.parentNode;

      if (parent.children.length === 1) {
        parent.parentNode.removeChild(parent);
      } else {
        parent.removeChild(this.element);
      }
    }, 300);

    this._clearTimeout();

    return false;
  }

  _clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
