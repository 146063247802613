import { fetchParams, fromSearch, toSearch } from 'globals';

import RefreshController from './refresh_controller';

export default class extends RefreshController {
  static targets = ['destroyButton', 'editButton', 'entryCheckbox'];

  channelName = 'EntriesChannel';

  allCheckboxClick(event) {
    [...this.element.querySelectorAll('.entry-checkbox')].forEach(
      (checkbox) => {
        checkbox.checked = event.target.checked;
        this.entryCheckboxClick();
      }
    );
  }

  checkedEntries() {
    return this.element.querySelectorAll('.entry-checkbox:checked');
  }

  destroyClick() {
    this._updateMultipleLink(this.destroyButtonTarget);
  }

  editClick() {
    this._updateMultipleLink(this.editButtonTarget);
  }

  entryCheckboxClick() {
    const checked = this.checkedEntries();

    if (checked.length > 0) {
      this.editButtonTarget.removeAttribute('disabled');
      this.destroyButtonTarget.removeAttribute('disabled');
    } else {
      this.editButtonTarget.setAttribute('disabled', 'disabled');
      this.destroyButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  _refresh() {
    const query = fromSearch(window.location.search);
    query.checked = [...this.checkedEntries()].map(
      (checkbox) => checkbox.dataset.id
    );
    const search = toSearch(query);

    fetch(`${this.element.dataset.url}?${search}`, fetchParams())
      .then((response) => response.text())
      .then((body) => {
        this.element.innerHTML = body;
      });
  }

  _updateMultipleLink(target) {
    const checked = this.checkedEntries();

    if (checked.length > 0) {
      const params = toSearch({
        id: [...checked].map((checkbox) => checkbox.dataset.id),
      });

      target.setAttribute('href', `${target.dataset.href}?${params}`);
    }
  }
}
