import { Controller } from 'stimulus';
import { _isPresent } from '../globals';
import tippy from 'tippy.js';

export default class extends Controller {
  connect() {
    let content = this.element.getAttribute('title');

    if (_isPresent(this.element.dataset.tooltip)) {
      const tooltip = document.getElementById(this.element.dataset.tooltip);

      if (tooltip) {
        content = tooltip.innerHTML;
      }
    }

    tippy(this.element, {
      allowHTML: true,
      content,
      theme: this.element.dataset.theme,
    });
  }
}
